<script>
import HorizontalMenu from '@/components/general/HorizontalMenu'
import Breadcrumbs from '@/components/general/Breadcrumbs'
export default {
  name: 'Departments',
  components: {
    HorizontalMenu,
    Breadcrumbs
  },
  data () {
    return {
      selectedMenu: 0,
      menuOptions: [
        { to: { name: 'business.admin.center.departments.active' }, text: 'admin.center.departments:menu.active', icon: 'mdi-account' },
        { to: { name: 'business.admin.center.departments.removed' }, text: 'admin.center.departments:menu.removed', icon: 'mdi-account-off' }
      ]
    }
  },
  computed: {
    breadcrumbsPaths () {
      const path = [{ text: 'admin.center:menu.4:title', href: this.$route.path }]
      return path
    }
  }
}
</script>
<template>
  <section class="departments--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths"/>
      <h2>{{ $t('admin.center:departments.title') }}</h2>
    </div>
    <horizontal-menu :options="menuOptions" v-model="selectedMenu"></horizontal-menu>
    <div class="departments--list">
      <router-view></router-view>
    </div>
  </section>
</template>
<style lang="scss">
.departments--container {
  .horizontal-menu--container {
    margin-bottom: 0px;
  }
  .departments--list {
    margin-top: -44px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1330px) {
  .departments--container {
    margin-bottom: 70px !important;
  }
}
@media only screen and (max-width: 940px) {
  .departments--container {
  .horizontal-menu--container {
    margin-bottom: 0px;
  }
  .departments--list {
    margin-top: 40px;
  }
}
}
</style>
